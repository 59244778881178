import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL', 'SMS'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: true,
    companyItInvoicingEnabled: false,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: true,

    favoriteFilterCategoriesIds: [2680, 3010, 3060, 3219, 3220, 3170, 3385, 3157, 3458, 3419, 3464, 3490, 4399, 2677],

    favoritePharmaciesEnabled: true,
    favoriteProductsEnabled: true,

    loyaltyCardBackImagePath: '/images/loyalty-card/ro-loyalty-card-back.png',
    loyaltyCardCodeLengthCheckEnabled: true,
    loyaltyCardImagePath: '/images/loyalty-card/ro-loyalty-card.png',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: true,
    fiscalCodeEnabled: true,
    loyaltyCardNumberDigits: 9,
    loyaltyCardNumberStaticPrefix: '++',
    loyaltyProgramBenefitsCount: 0,
    myAccountSorpEnabled: false,
    standAloneCardModeEnabled: false,

    phoneNumberRequired: true,

    priceEnabled: false,
    priceSummaryEnabled: true,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    returnAndComplaintTitleSplitEnabled: true,
    returnIbanFieldEnabled: true,

    sexEnabled: true,
    sexThirdOptionEnabled: false,
    sexRequiredEnabled: true,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: false,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: envBool(process.env.EMAIL_RESTRICTED_DOMAIN_ENABLED, false),

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: true,
      breastFeeding: true,
      allergies: true,
      treatments: true,
      height: true,
      weight: true,
    },
  },
}
