import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import footer from './footer.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import marketplace from './marketplace.mjs'
import inputTypes from './inputTypes.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...footer,
  ...termsPages,
  ...paymentCodes,
  ...marketplace,
  ...inputTypes,
  companyDetailFieldsOrder: {
    crn: 4,
    vatId: 1,
  },
  defaultAgreements: () => [],
  firstPartySellerId: '0',
  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'general',
  shippingInfoBlocks: '',
  stockStatusChunkSize: 50,
  thankYouText: {
    payment: 'SMS',
    dispatch: 'email',
    pickupSecondPartDisabled: true,
  },
  ...config,
})
