export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*&menu=*',
    '*&rating=*',
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?cjdata*',
    '*?fbclid*',
    '*?fresh*',
    '*?gbraid*',
    '*?gclid*',
    '*?menu=*',
    '*?network*',
    '*?page*',
    '*?p=*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*?utm*',
    '*?y_source*',
    '/_i/*',
    '/i18n/*'
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '/*2pau',
    '/*2ptt',
    '/*2ptu',
    '/*2prp',
    '/*2pdlst',
    '*/graphql*',
    '/checkout/*',
    '/produse-favorite$',
    '/contul-meu$',
    '/contul-meu/comenzi$',
    '/contul-meu/editeaza$',
    '/contul-meu/lista-favorite$',
    '/contul-meu/alerte$',
    '/dr-max-diomax-30-capsule',
    '/dr-max-diomax-simple-120-comprimate-filmate',
    '/dr-max-diomax-gel-125ml',
    '/dr-max-diomax-30-comprimate',
  ],
}
